<template>
  <table class="flex flex-col gap-y-7 lg:gap-y-9 overflow-auto">
    <thead v-if="firstRowIsHead && !headIsHidden" :class="{ 'min-w-[500px]': hasMinWidth }">
      <tr class="grid grid-flow-col lg:gap-4 auto-cols-fr items-center">
        <td
          v-for="(value, index) in headData"
          :key="index"
          class="uppercase text-xs lg:text-sm text-center text-grey-light font-semibold tracking-wider"
        >
          {{ value }}
        </td>
      </tr>
    </thead>
    <tbody
      class="grid sm:gap-y-7 lg:gap-y-9"
      :class="{
        'min-w-[500px]': hasMinWidth && designType !== 'tile',
        'dotted gap-y-7': designType === 'primary',
        'divide-y divide-secondary-dark sm:divide-none': designType === 'tile',
      }"
    >
      <tr
        v-for="(item, rowIndex) in Object.entries(table)"
        :key="rowIndex"
        class="grid gap-4 lg:gap-7 auto-cols-fr items-center"
        :class="{
          'py-5 first:pt-0 sm:py-0 grid-flow-row sm:grid-flow-col': designType === 'tile',
          'grid-flow-col': designType === 'primary',
        }"
      >
        <td v-show="!/^\d+$/.test(item[0])" class="descriptive">{{ item[0] }}</td>
        <td
          v-for="(value, columnIndex) in Object.values(item[1])"
          :key="columnIndex"
          class="text-grey font-semibold text-base lg:text-xl relative col-span-2"
          :class="{
            'bg-white p-7 lg:p-10 h-full flex flex-col justify-center rounded-xl': designType === 'tile',
            'text-center': designType === 'primary',
          }"
        >
          <span v-if="firstRowIsHead && designType === 'tile'" class="descriptive">{{ headData[columnIndex] }}</span>
          {{ value }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
const props = defineProps({
  table: {
    type: Object,
    required: true,
  },
  firstRowIsHead: {
    type: Boolean,
    default: false,
  },
  hasMinWidth: {
    type: Boolean,
    default: false,
  },
  headIsHidden: {
    type: Boolean,
    default: false,
  },
  designType: {
    type: String,
    default: 'primary',
  },
})

const headData = ref<string[]>([])

if (props.firstRowIsHead) {
  headData.value = props.table[0] || []
  props.table.shift()
}
</script>

<style scoped>
.descriptive {
  @apply uppercase text-xs lg:text-sm text-grey-light font-semibold tracking-wider;
}

tbody.dotted tr td:not(:first-child):not(:nth-child(2))::before {
  @apply content-['•'] absolute -left-[3px] lg:-left-[10px] text-[#C6C2BE] transform top-1/2 -translate-y-1/2;
}
</style>
